import { Box, Image } from "@chakra-ui/react";
import { useExpert } from "src/hooks/data";
import { withSuspense } from "src/utils/withSuspense";

export const ExpertInfo = withSuspense(
  ({ photo = true }: { photo?: boolean }) => {
    const expert = useExpert();
    if (!expert) {
      return null;
    }
    const name = expert.traits.name;
    const fullName = `${name.first} ${name.last}`;
    return (
      <Box
        display="flex"
        // alignItems="center"
        justifyContent="center"
        flexDir="column"
        gap={2}
      >
        {photo && expert.photo && (
          <Image
            mx="auto"
            rounded="full"
            height={120}
            width={120}
            src={expert.photo}
            alt="ügyintéző profilképe"
            mb={4}
          />
        )}

        <Box>
          Az Ön ügyintézője: <b>{fullName}</b>
        </Box>
        <Box>
          Telefonszám:{" "}
          <Box
            color="brand.500"
            as="a"
            fontWeight={700}
            href={`tel:${expert.traits.phone}`}
          >
            {expert.traits.phone}
          </Box>
        </Box>
        <Box>
          E-mail:{" "}
          <Box
            color="brand.500"
            as="a"
            fontWeight={700}
            href={`mailto:${expert.traits.phone}`}
          >
            {expert.traits.email}
          </Box>
        </Box>
        <Box>
          A folyamattal kapcsolatos hasznos információkért látogasson el{" "}
          <Box
            as="a"
            href="https://www.atotalinsurtech.com/gepjarmu-karrendezes"
            color="brand.500"
            fontWeight={700}
          >
            weboldalunkra
          </Box>
          !
        </Box>
      </Box>
    );
  },
  "",
  {
    clientOnly: true,
  }
);

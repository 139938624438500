import { usePageContext } from "src/hooks/usePageContext";

export const useSearchParams = () => {
  const ctx = usePageContext();
  return ctx.urlParsed.search;
};

export const getSessionId = () => {
  return sessionStorage.getItem("session-id");
};
export const setSessionId = (sessionId: string) => {
  return sessionStorage.setItem("session-id", sessionId);
};

export const getInviteId = () => {
  return sessionStorage.getItem("invite-id");
};

export const setInviteId = (inviteId: string) => {
  return sessionStorage.setItem("invite-id", inviteId);
};

import { getInitialMessages, response } from "server/flowmanager";
import { videoCollabManagementApi } from "server/openapi";
import { createSessionForClaim, getSession } from "server/session";
import { Message, Node } from "./types";

export const openInvite = async (inviteId: string) => {
  const res = await videoCollabManagementApi.getInviteVideoCollabInvitesIdGet({
    id: inviteId,
    userOpen: true,
  });
  const invite = res.data;
  const claimId = invite.caseId;
  const flowName = invite.flowName as any;

  if (!claimId || !flowName) {
    throw new Error("Invalid invite");
  }

  const claim = {
    claimId,
    flowName,
  };

  const session = await createSessionForClaim(claim);

  const sessionId = session.id;

  return {
    sessionId,
    id: invite.id,
    valid: !invite.invalidated,
  };
};

export const getExpert = async ({ inviteId }: { inviteId: string }) => {
  const res = await videoCollabManagementApi.getInviteVideoCollabInvitesIdGet({
    id: inviteId,
  });
  const invite = res.data;
  const editor = invite.editors.length ? invite.editors[0] : null;
  return editor;
};

export const init = async ({
  sessionId,
}: {
  sessionId: string;
}): Promise<Node> => {
  const session = await getSession({ id: sessionId });
  const nextNode = getInitialMessages(session);
  return nextNode;
};

export const message = async ({
  sessionId,
  message,
}: {
  sessionId: string;
  message: Message;
}) => {
  const session = await getSession({ id: sessionId });
  const nextNode = await response(session, message);
  return nextNode;
};

import { getExpert } from "shared/client.telefunc";
import { getInviteId } from "src/utils/params";
import { useQuery } from "./useQuery";

export const useExpert = () => {
  const methods = useQuery("getExpert", () =>
    getExpert({
      inviteId: getInviteId()!,
    })
  );
  return methods.data;
};
